import "core-js/modules/es.array.filter";import "core-js/modules/es.array.find";import "core-js/modules/es.array.for-each";import "core-js/modules/es.date.to-string";import "core-js/modules/es.number.constructor";import "core-js/modules/es.object.keys";import "core-js/modules/es.parse-int";import "core-js/modules/web.dom-collections.for-each";import _objectSpread from "/Users/learning/Documents/workspace/kindstar/customer-orders/node_modules/@babel/runtime/helpers/esm/objectSpread2"; //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint-disable vue/require-v-for-key */
/* eslint-disable radix */

import calendar from './calendar';

export default {
  props: {
    // 多选模式
    multi: {
      type: Boolean,
      "default": false },

    // 范围模式
    range: {
      type: Boolean,
      "default": false },

    // 默认日期
    value: {
      type: Array, "default": function _default()
      {
        return [];
      } },

    // 开始选择日期
    begin: {
      type: Array, "default": function _default()
      {
        return [];
      } },

    // 结束选择日期
    end: {
      type: Array, "default": function _default()
      {
        return [];
      } },


    // 是否小于10补零
    zero: {
      type: Boolean,
      "default": false },

    // 屏蔽的日期
    disabled: {
      type: Array, "default": function _default()
      {
        return [];
      } },

    // 是否显示农历
    lunar: {
      type: Boolean,
      "default": false },


    // 自定义星期名称
    weeks: {
      type: Array, "default": function _default()
      {
        return window.navigator.language.toLowerCase() == 'zh-cn' ?
        ['日', '一', '二', '三', '四', '五', '六'] :
        ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      } },

    // 自定义月份
    months: {
      type: Array, "default": function _default()
      {
        return window.navigator.language.toLowerCase() == 'zh-cn' ?
        [
        '一月',
        '二月',
        '三月',
        '四月',
        '五月',
        '六月',
        '七月',
        '八月',
        '九月',
        '十月',
        '十一月',
        '十二月'] :

        [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'];

      } },

    // 自定义事件
    events: {
      type: Object, "default": function _default()
      {
        return {};
      } },

    // 标记
    marks: {
      type: Array, "default": function _default()
      {
        return [];
      } },

    // 禁止选择（记事本）
    stopSelect: {
      type: Boolean,
      "default": false } },


  data: function data() {
    return {
      years: [],
      yearsShow: false,
      year: 0,
      month: 0,
      day: 0,
      days: [],
      multiDays: [],
      today: [],
      festival: {
        lunar: {
          '1-1': '春节',
          '1-15': '元宵节',
          '2-2': '龙头节',
          '5-5': '端午节',
          '7-7': '七夕节',
          '7-15': '中元节',
          '8-15': '中秋节',
          '9-9': '重阳节',
          '10-1': '寒衣节',
          '10-15': '下元节',
          '12-8': '腊八节',
          '12-23': '祭灶节' },

        gregorian: {
          '1-1': '元旦',
          '2-14': '情人节',
          '3-8': '妇女节',
          '3-12': '植树节',
          '4-5': '清明节',
          '5-1': '劳动节',
          '5-4': '青年节',
          '6-1': '儿童节',
          '7-1': '建党节',
          '8-1': '建军节',
          '9-10': '教师节',
          '10-1': '国庆节',
          '12-24': '平安夜',
          '12-25': '圣诞节' } },


      rangeBegin: [],
      rangeEnd: [] };

  },
  watch: {
    events: function events() {
      this.render(this.year, this.month);
    },
    marks: function marks() {
      this.render(this.year, this.month);
    },
    value: function value() {
      this.init();
    } },

  mounted: function mounted() {
    this.init();
  },
  methods: {
    init: function init() {
      var now = new Date();

      this.year = now.getFullYear();
      this.month = now.getMonth();
      this.day = now.getDate();
      if (this.value.length > 0) {
        if (this.range) {
          // 范围
          this.year = parseInt(this.value[0][0]);
          this.month = parseInt(this.value[0][1]) - 1;
          this.day = parseInt(this.value[0][2]);

          var year2 = parseInt(this.value[1][0]);
          var month2 = parseInt(this.value[1][1]) - 1;
          var day2 = parseInt(this.value[1][2]);

          this.rangeBegin = [this.year, this.month, this.day];
          this.rangeEnd = [year2, month2, day2];
        } else if (this.multi) {
          // 多选
          this.multiDays = this.value;
          this.year = parseInt(this.value[0][0]);
          this.month = parseInt(this.value[0][1]) - 1;
          this.day = parseInt(this.value[0][2]);
        } else {
          // 单选
          this.year = parseInt(this.value[0]);
          this.month = parseInt(this.value[1]) - 1;
          this.day = parseInt(this.value[2]);
        }
      }
      this.$emit('initcalendar', this.year, this.month + 1);
      this.render(this.year, this.month);
    },
    // 渲染日期
    render: function render(y, m) {var _this = this;
      var firstDayOfMonth = new Date(y, m, 1).getDay(); // 当月第一天
      var lastDateOfMonth = new Date(y, m + 1, 0).getDate(); // 当月最后一天
      var lastDayOfLastMonth = new Date(y, m, 0).getDate(); // 最后一月的最后一天

      this.year = y;
      var seletSplit = this.value;

      var i;

      var line = 0;
      var temp = [];

      var nextMonthPushDays = 1;

      for (i = 1; i <= lastDateOfMonth; i++) {
        var day = new Date(y, m, i).getDay(); // 返回星期几（0～6）

        var k = void 0;
        // 第一行

        if (day == 0) {
          temp[line] = [];
        } else if (i == 1) {
          temp[line] = [];
          k = lastDayOfLastMonth - firstDayOfMonth + 1;
          for (var j = 0; j < firstDayOfMonth; j++) {
            // console.log("第一行",lunarYear,lunarMonth,lunarValue,lunarInfo)
            temp[line].push(_objectSpread(_objectSpread({
              day: k,
              disabled: true },
            this.getLunarInfo(
            this.computedPrevYear(),
            this.computedPrevMonth(true),
            k)),

            this.getEventsAndMarks(
            this.computedPrevYear(),
            this.computedPrevMonth(true),
            k)));


            k++;
          }
        }

        if (this.range) {
          // 范围
          // console.log("日期范围",this.getLunarInfo(this.year,this.month+1,i))
          var options = _objectSpread(_objectSpread({
            day: i },
          this.getLunarInfo(this.year, this.month + 1, i)),
          this.getEventsAndMarks(this.year, this.month + 1, i));


          if (this.rangeBegin.length > 0) {
            var beginTime = Number(
            new Date(
            this.rangeBegin[0],
            this.rangeBegin[1],
            this.rangeBegin[2]));


            var endTime = Number(
            new Date(this.rangeEnd[0], this.rangeEnd[1], this.rangeEnd[2]));

            var stepTime = Number(new Date(this.year, this.month, i));

            if (beginTime <= stepTime && endTime >= stepTime) {
              options.selected = true;
            }
          }
          if (this.begin.length > 0) {
            var _beginTime = Number(
            new Date(
            parseInt(this.begin[0]),
            parseInt(this.begin[1]) - 1,
            parseInt(this.begin[2])));



            if (_beginTime > Number(new Date(this.year, this.month, i))) {
              options.disabled = true;
            }
          }
          if (this.end.length > 0) {
            var _endTime = Number(
            new Date(
            parseInt(this.end[0]),
            parseInt(this.end[1]) - 1,
            parseInt(this.end[2])));



            if (_endTime < Number(new Date(this.year, this.month, i))) {
              options.disabled = true;
            }
          }
          if (this.disabled.length > 0) {
            if (
            this.disabled.filter(
            function (v) {return _this.year === v[0] && _this.month === v[1] - 1 && i === v[2];}).
            length > 0)
            {
              options.disabled = true;
            }
          }
          temp[line].push(options);
        } else if (this.multi) {
          // 多选
          var _options = void 0;
          // 判断是否选中

          if (
          this.value.filter(
          function (v) {return _this.year === v[0] && _this.month === v[1] - 1 && i === v[2];}).
          length > 0)
          {
            _options = _objectSpread(_objectSpread({
              day: i,
              selected: true },
            this.getLunarInfo(this.year, this.month + 1, i)),
            this.getEventsAndMarks(this.year, this.month + 1, i));

          } else {
            _options = _objectSpread(_objectSpread({
              day: i,
              selected: false },
            this.getLunarInfo(this.year, this.month + 1, i)),
            this.getEventsAndMarks(this.year, this.month + 1, i));

            if (this.begin.length > 0) {
              var _beginTime2 = Number(
              new Date(
              parseInt(this.begin[0]),
              parseInt(this.begin[1]) - 1,
              parseInt(this.begin[2])));



              if (_beginTime2 > Number(new Date(this.year, this.month, i))) {
                _options.disabled = true;
              }
            }
            if (this.end.length > 0) {
              var _endTime2 = Number(
              new Date(
              parseInt(this.end[0]),
              parseInt(this.end[1]) - 1,
              parseInt(this.end[2])));



              if (_endTime2 < Number(new Date(this.year, this.month, i))) {
                _options.disabled = true;
              }
            }
            if (this.disabled.length > 0) {
              if (
              this.disabled.filter(
              function (v) {return _this.year === v[0] && _this.month === v[1] - 1 && i === v[2];}).
              length > 0)
              {
                _options.disabled = true;
              }
            }
          }

          temp[line].push(_options);
        } else {
          // 单选
          // console.log(this.lunar(this.year,this.month,i));

          var chk = new Date();
          var chkY = chk.getFullYear();
          var chkM = chk.getMonth();
          // 匹配上次选中的日期

          if (
          parseInt(seletSplit[0]) == this.year &&
          parseInt(seletSplit[1]) - 1 == this.month &&
          parseInt(seletSplit[2]) == i)
          {
            // console.log("匹配上次选中的日期",lunarYear,lunarMonth,lunarValue,lunarInfo)
            temp[line].push(_objectSpread(_objectSpread({
              day: i,
              selected: true },
            this.getLunarInfo(this.year, this.month + 1, i)),
            this.getEventsAndMarks(this.year, this.month + 1, i)));

            this.today = [line, temp[line].length - 1];
          }
          // 没有默认值的时候显示选中今天日期
          else if (
          chkY == this.year &&
          chkM == this.month &&
          i == this.day &&
          this.value == '')
          {
            // console.log("今天",lunarYear,lunarMonth,lunarValue,lunarInfo)
            temp[line].push(_objectSpread(_objectSpread({
              day: i,
              selected: true },
            this.getLunarInfo(this.year, this.month + 1, i)),
            this.getEventsAndMarks(this.year, this.month + 1, i)));

            this.today = [line, temp[line].length - 1];
          } else {
            // 普通日期
            // console.log("设置可选范围",i,lunarYear,lunarMonth,lunarValue,lunarInfo)
            var _options2 = _objectSpread(_objectSpread({
              day: i,
              selected: false },
            this.getLunarInfo(this.year, this.month + 1, i)),
            this.getEventsAndMarks(this.year, this.month + 1, i));


            if (this.begin.length > 0) {
              var _beginTime3 = Number(
              new Date(
              parseInt(this.begin[0]),
              parseInt(this.begin[1]) - 1,
              parseInt(this.begin[2])));



              if (_beginTime3 > Number(new Date(this.year, this.month, i))) {
                _options2.disabled = true;
              }
            }
            if (this.end.length > 0) {
              var _endTime3 = Number(
              new Date(
              parseInt(this.end[0]),
              parseInt(this.end[1]) - 1,
              parseInt(this.end[2])));



              if (_endTime3 < Number(new Date(this.year, this.month, i))) {
                _options2.disabled = true;
              }
            }
            if (this.disabled.length > 0) {
              if (
              this.disabled.filter(
              function (v) {return _this.year === v[0] && _this.month === v[1] - 1 && i === v[2];}).
              length > 0)
              {
                _options2.disabled = true;
              }
            }
            temp[line].push(_options2);
          }
        }
        // 到周六换行
        if (day == 6 && i < lastDateOfMonth) {
          line++;
        } else if (i == lastDateOfMonth) {
          // line++
          var _k = 1;

          for (var d = day; d < 6; d++) {
            // console.log(this.computedNextYear()+"-"+this.computedNextMonth(true)+"-"+k)
            temp[line].push(_objectSpread(_objectSpread({
              day: _k,
              disabled: true },
            this.getLunarInfo(
            this.computedNextYear(),
            this.computedNextMonth(true),
            _k)),

            this.getEventsAndMarks(
            this.computedNextYear(),
            this.computedNextMonth(true),
            _k)));


            _k++;
          }
          // 下个月除了补充的前几天开始的日期
          nextMonthPushDays = _k;
        }
      } // end for

      // console.log(this.year+"/"+this.month+"/"+this.day+":"+line)
      // 补充第六行让视觉稳定
      if (line <= 5 && nextMonthPushDays > 0) {
        // console.log({nextMonthPushDays:nextMonthPushDays,line:line})
        for (var _i = line + 1; _i <= 5; _i++) {
          temp[_i] = [];
          var start = nextMonthPushDays + (_i - line - 1) * 7;

          for (var _d = start; _d <= start + 6; _d++) {
            temp[_i].push(_objectSpread(_objectSpread({
              day: _d,
              disabled: true },
            this.getLunarInfo(
            this.computedNextYear(),
            this.computedNextMonth(true),
            _d)),

            this.getEventsAndMarks(
            this.computedNextYear(),
            this.computedNextMonth(true),
            _d)));


          }
        }
      }
      this.days = temp;
    },
    computedPrevYear: function computedPrevYear() {
      var value = this.year;

      if (this.month - 1 < 0) {
        value--;
      }
      return value;
    },
    computedPrevMonth: function computedPrevMonth(isString) {
      var value = this.month;

      if (this.month - 1 < 0) {
        value = 11;
      } else {
        value--;
      }
      // 用于显示目的（一般月份是从0开始的）
      if (isString) {
        return value + 1;
      }
      return value;
    },
    computedNextYear: function computedNextYear() {
      var value = this.year;

      if (this.month + 1 > 11) {
        value++;
      }
      return value;
    },
    computedNextMonth: function computedNextMonth(isString) {
      var value = this.month;

      if (this.month + 1 > 11) {
        value = 0;
      } else {
        value++;
      }
      // 用于显示目的（一般月份是从0开始的）
      if (isString) {
        return value + 1;
      }
      return value;
    },
    // 获取农历信息
    getLunarInfo: function getLunarInfo(y, m, d) {
      var lunarInfo = calendar.solar2lunar(y, m, d);

      var lunarValue = lunarInfo.IDayCn;
      // console.log(lunarInfo)

      var isLunarFestival = false;

      var isGregorianFestival = false;

      if (
      this.festival.lunar[lunarInfo.lMonth + '-' + lunarInfo.lDay] !=
      undefined)
      {
        lunarValue = this.festival.lunar[
        lunarInfo.lMonth + '-' + lunarInfo.lDay];

        isLunarFestival = true;
      } else if (this.festival.gregorian[m + '-' + d] != undefined) {
        lunarValue = this.festival.gregorian[m + '-' + d];
        isGregorianFestival = true;
      }
      return {
        lunar: lunarValue,
        isLunarFestival: isLunarFestival,
        isGregorianFestival: isGregorianFestival };

    },
    // 获取自定义事件 标记 marks
    getEventsAndMarks: function getEventsAndMarks(y, m, d) {
      var data = {};

      if (Object.keys(this.events).length) {
        var eventName = this.events[y + '-' + m + '-' + d];

        if (eventName != undefined) {
          data.eventName = eventName;
        }
      }
      // 标记 marks
      if (this.marks.length) {
        var markName = y + '-' + m + '-' + d;

        if (this.marks.find(function (i) {return i == markName;})) {
          data.marked = true;
        }
      }
      return data;
    },
    // 上月
    prev: function prev(e) {
      e.stopPropagation();
      if (this.month == 0) {
        this.month = 11;
        this.year = parseInt(this.year) - 1;
      } else {
        this.month = parseInt(this.month) - 1;
      }
      this.render(this.year, this.month);
      this.$emit('selectMonth', this.month + 1, this.year);
      this.$emit('prev', this.month + 1, this.year);
    },
    //  下月
    next: function next(e) {
      e.stopPropagation();
      if (this.month == 11) {
        this.month = 0;
        this.year = parseInt(this.year) + 1;
      } else {
        this.month = parseInt(this.month) + 1;
      }
      this.render(this.year, this.month);
      this.$emit('selectMonth', this.month + 1, this.year);
      this.$emit('next', this.month + 1, this.year);
    },
    // 选中日期
    select: function select(k1, k2, e) {var _this2 = this;
      if (this.stopSelect) {
        return;
      }
      if (e != undefined) {
        e.stopPropagation();
      }
      // 日期范围
      if (this.range) {
        if (this.rangeBegin.length == 0 || this.rangeEndTemp != 0) {
          this.rangeBegin = [this.year, this.month, this.days[k1][k2].day];
          this.rangeBeginTemp = this.rangeBegin;
          this.rangeEnd = [this.year, this.month, this.days[k1][k2].day];
          this.rangeEndTemp = 0;
        } else {
          this.rangeEnd = [this.year, this.month, this.days[k1][k2].day];
          this.rangeEndTemp = 1;
          // 判断结束日期小于开始日期则自动颠倒过来
          if (
          Number(new Date(this.rangeEnd[0], this.rangeEnd[1], this.rangeEnd[2])) <
          Number(new Date(
          this.rangeBegin[0],
          this.rangeBegin[1],
          this.rangeBegin[2])))

          {
            this.rangeBegin = this.rangeEnd;
            this.rangeEnd = this.rangeBeginTemp;
          }
          // 小于10左边打补丁
          var begin = [];

          var end = [];

          if (this.zero) {
            this.rangeBegin.forEach(function (v, k) {
              if (k == 1) {
                v += 1;
              }
              begin.push(_this2.zeroPad(v));
            });
            this.rangeEnd.forEach(function (v, k) {
              if (k == 1) {
                v += 1;
              }
              end.push(_this2.zeroPad(v));
            });
          } else {
            begin = this.rangeBegin;
            end = this.rangeEnd;
          }
          // console.log("选中日期",begin,end)
          this.$emit('select', begin, end);
        }
        this.render(this.year, this.month);
      } else if (this.multi) {
        // 如果已经选过则过滤掉
        var filterDay = this.multiDays.filter(
        function (v) {return _this2.year === v[0] &&
          _this2.month === v[1] - 1 &&
          _this2.days[k1][k2].day === v[2];});


        if (filterDay.length > 0) {
          this.multiDays = this.multiDays.filter(
          function (v) {return _this2.year !== v[0] ||
            _this2.month !== v[1] - 1 ||
            _this2.days[k1][k2].day !== v[2];});

        } else {
          this.multiDays.push([
          this.year,
          this.month + 1,
          this.days[k1][k2].day]);

        }
        this.days[k1][k2].selected = !this.days[k1][k2].selected;
        this.$emit('select', this.multiDays);
      } else {
        // 取消上次选中
        if (this.today.length > 0) {
          this.days.forEach(function (v) {
            v.forEach(function (vv) {
              vv.selected = false;
            });
          });
        }
        // 设置当前选中天
        this.days[k1][k2].selected = true;
        this.day = this.days[k1][k2].day;
        this.today = [k1, k2];
        this.$emit('select', [
        this.year,
        this.zero ? this.zeroPad(this.month + 1) : this.month + 1,
        this.zero ?
        this.zeroPad(this.days[k1][k2].day) :
        this.days[k1][k2].day]);

      }
    },
    changeYear: function changeYear() {
      if (this.yearsShow) {
        this.yearsShow = false;
        return false;
      }
      this.yearsShow = true;
      this.years = [];
      for (var i = ~~this.year - 10; i < ~~this.year + 10; i++) {
        this.years.push(i);
      }
    },
    selectYear: function selectYear(value) {
      this.yearsShow = false;
      this.year = value;
      this.render(this.year, this.month);
      this.$emit('selectYear', value);
    },
    // // 返回今天
    // setToday() {
    //   const now = new Date()
    //   this.year = now.getFullYear()
    //   this.month = now.getMonth()
    //   this.day = now.getDate()
    //   this.render(this.year, this.month)
    //   // 遍历当前日找到选中
    //   this.days.forEach(v => {
    //     const day = v.find(vv => vv.day == this.day && !vv.disabled)
    //     if (day != undefined) {
    //       day.selected = true
    //     }
    //   })
    // },

    // 日期补零
    zeroPad: function zeroPad(n) {
      return String(n < 10 ? '0' + n : n);
    } } };